import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountCenter } from './AccountCenter';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
  return (
    <nav className="navbar border-bottom">
      <div className="navbar-title">
        <Link to="/" className="navbar-title-link">
          <div className="navbar-logo">
            <img src="https://shared.sgmarkets.com/assets/images/logo/socgen_logo_full.svg" alt="SG full logo" />
          </div>
          <div className="navbar-title-divider"></div>
          <div className="navbar-service-name">Status</div>
        </Link>
        <button
          className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-offset="0,4"
          aria-expanded="true"
        >
          <svg
            className="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2" y="6" width="20" height="2" fill="currentColor"></rect>
            <rect x="2" y="11" width="20" height="2" fill="currentColor"></rect>
            <rect x="2" y="16" width="20" height="2" fill="currentColor"></rect>
          </svg>
        </button>
        <ul className="navbar-menu-dropdown dropdown-menu w-100">
          <li className="navbar-item">
            <Link to="/" className="navbar-link active">
              <FormattedMessage id="menu.title" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <Link className="navbar-link active" aria-current="page" to="/">
              <FormattedMessage id="menu.title" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar-toolbar">
        <AccountCenter />
      </div>
    </nav>
  );
};
