import React from 'react';

export const HelpCenter: React.FC = () => (
  <aside>
    <sgwt-help-center
      application-id={import.meta.env.VITE_APP_ID}
      default-send-to={import.meta.env.VITE_APP_CONTACT_MAIL}
      mail-subject="User message"
      sg-connect-support="sg-connect-v2"
    />
  </aside>
);
