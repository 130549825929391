import React, { ReactNode } from 'react';

declare global {
  namespace JSX {
    // tslint:disable-next-line: interface-name
    interface IntrinsicElements {
      'sg-error-page': {
        code: 404 | 500 | 503;
        theme?: 'dark' | 'standard';
        'action-button-label'?: string;
        'action-button-link'?: string;
        'contact-us'?: 'help-center' | string;
        fullscreen?: boolean;
        'description-label'?: string;
        'title-label'?: string;
      };
    }
  }
}

interface IErrorBoundaryState {
  error: Error | null;
}

interface IErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(errorDetected: Error, errorInfo: React.ErrorInfo) {
    console.error(
      `ErrorBoundary error[${errorDetected.message}] stack[${errorDetected.stack}] errorInfo[${errorInfo}]`
    );
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div style={{ height: '100vh' }}>
          <sgwt-help-center
            sg-connect-support="sg-connect-v2"
            application-id={import.meta.env.VITE_APP_ID}
            default-send-to={import.meta.env.VITE_APP_CONTACT_MAIL}
            message-only="true"
            message-template={`Hello support.
            
I encountered an issue while using the service.
-----------------
Technical details
Error message: ${error.message}
Stack trace: ${error.stack}
            `}
          />

          <sg-error-page
            code={500}
            action-button-label="Back to home"
            action-button-link="/"
            contact-us="help-center"
            fullscreen={true}
            description-label={error.message}
            title-label="SGM Design Lab - Error"
          />
        </div>
      );
    }

    return children;
  }
}
