import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from './context/IntlContext';
import { AppGlobalContext, CachedStatus, GlobalContext } from './context/GlobalContext';
import { ErrorBoundary, Header, HelpCenter, MiniFooter } from './components/common';
import { Loading } from './components/common/Loading';
import './App.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Lazy loading of sub-pages. With Vite.js, it creates separate JS bundles for each page,
// which are loaded only on demand when the user go on the corresponding page.
const PageNotFound = React.lazy(() => import('./components/common/PageNotFound'));
const Home = React.lazy(() => import('./routes/home/Home'));

// Create a client
const queryClient = new QueryClient();

interface Props {
  sgConnectFailed?: boolean;
}

const App: React.FC<Props> = ({ sgConnectFailed }) => {
  const [cachedStatus, setCachedStatus] = React.useState<CachedStatus | undefined>(undefined);

  const context: AppGlobalContext = {
    language: 'en',
    cachedStatus,
    setCachedStatus,
  };

  return (
    <ErrorBoundary>
      <IntlProvider>
        <GlobalContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Header />
              <div className="flex-grow-1">
                {sgConnectFailed && (
                  <div className="alert alert-danger" role="alert">
                    Failed to reach SG connect
                  </div>
                )}
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </div>
              <HelpCenter />
              <MiniFooter />
            </Router>
          </QueryClientProvider>
        </GlobalContext.Provider>
      </IntlProvider>
    </ErrorBoundary>
  );
};

export default App;
