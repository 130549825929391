import React from 'react';

export const MiniFooter: React.FC = () => {
  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      container="container"
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
    />
  );
};
