import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './App';
// Load SG Bootstrap v5 CSS
import '@sg-bootstrap/icons/dist/index.css';
import '@sg-bootstrap/core/dist/css/sg-bootstrap-standard.css';
import { SgwtConnectHTMLElement } from './sgwt-widgets';

declare global {
  interface ImportMeta {
    // @ts-ignore
    env: ImportMetaEnv;
  }
}

// Auto-login with SG Connect, using the <sgwt-connect> widget.
const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
if (widget === null) {
  document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
} else if (widget.sgwtConnect) {
  // Widget is already initialized, so can make the SG Connect login...
  login();
} else {
  // Widget is not yet initialized, we need to wait for the "ready" event.
  widget.addEventListener('sgwt-connect--ready', login);
}

async function login() {
  if (!widget) {
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
    return;
  }
  const sgwtConnect = widget.sgwtConnect;
  widget.removeEventListener('sgwt-connect--ready', login);
  if (sgwtConnect.getAuthorizationError()) {
    // Case #1: an error occurred during the retrieval of the SG Connect token...
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${sgwtConnect.getAuthorizationError()}.
      </div>`;
  } else if (sgwtConnect.isAuthorized()) {
    // ? Case #2: the user is authenticated, we load the application
    // ! We delay the loading of the application by 0.5s in order to display the SGM Service Loader.
    // ! The component is defined in the main index.html, within the <div id="root"> element.
    // ! Remove that timeout if you do not need this Service Loader component.
     const root = createRoot(document.querySelector('#app')!);
      setTimeout(() => {
        root.render(
          <React.StrictMode>
            <App />
          </React.StrictMode>
        );
      }, 500);
  } else {
    console.error('SG connect down, rendering app without authentication');
    // Case #4: Sg connect is down, render app without logging in
    const root = createRoot(document.querySelector('#app')!);

    root.render(
      <React.StrictMode>
        <App sgConnectFailed={true} />
      </React.StrictMode>
    );
  }
}
