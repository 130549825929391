import { Dto } from 'interface';
import React from 'react';

export type CachedStatus = {
  status: Dto.Response<Dto.Status.Global> | undefined;
  fetchTime: Date;
};

export interface AppGlobalContext {
  language: string;
  cachedStatus: CachedStatus | undefined;
  setCachedStatus: (data: CachedStatus) => void;
}

export interface GlobalContextProps {
  globalContext: AppGlobalContext;
}

export const defaultContext: AppGlobalContext = {
  language: 'en',
  cachedStatus: undefined,
  setCachedStatus: () => {},
};

export const GlobalContext = React.createContext<AppGlobalContext>(defaultContext);

export function getDisplayName<T = {}>(WrappedComponent: React.ComponentType<T>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withGlobalContext = <P extends GlobalContextProps>(WrappedComponent: React.ComponentType<P>) => {
  const wrapper: React.FC<Pick<P, Exclude<keyof P, keyof GlobalContextProps>>> = (props) => (
    <GlobalContext.Consumer>{(context) => <WrappedComponent {...(props as P)} {...context} />}</GlobalContext.Consumer>
  );
  wrapper.displayName = `WithGlobalContext(${getDisplayName(WrappedComponent)})`;
  return wrapper;
};
